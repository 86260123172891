export const header = [
    'Reference No',
    'Duplicate No.',
    'สถานะแจ้งเคลม',
    'สถานะเอกสาร',
    'Claim IIA',
    'เลขกรรมธรรม์',
    'วันที่แจ้งเคลม',
    'ประเภทผู้เอาประกัน',
    'ชื่อบริษัท',
    'เลขประจำตัวผู้เสียภาษี',
    'ชื่อผู้เอาประกัน',
    'นามสกุลผู้เอาประกัน',
    'ชื่อผู้แจ้ง',
    'นามสกุลผู้แจ้ง',
    'เลขบัตรประชาชน',
    'ความสัมพันธ์กับผู้เอาประกัน',
    'เบอร์ติดต่อ 1',
    'เบอร์ติดต่อ 2',
    'อีเมล',
    'ที่อยู่ติดต่อ',
    '',
    '',
    '',
    '',
    '',
    '',
    'ประเภทประกันภัย',
    'ประเภทกรมธรรม์',
    'รายละเอียดกรมธรรม์',
    'สาเหตุการแจ้งเคลม',
    'สถานที่เกิดเหตุ',
    'ลักษณะที่เกิดเหตุ',
    'วันที่เกิดเหตุ',
    'ยอดเงินเรียกร้อง',
    'ยอดอนุมัติค่าสินไหม',
    'วันที่นัดจ่ายค่าสินไหม',
    'ช่องทางรับค่าสินไหม',
    'ธนาคาร',
    'สาขา',
    'เลขบัญชี',
    'ชื่อบัญชี',
    'remark',
    'เอกสารพิจารณาเพิ่ม',
    'เอกสารลงนาม',
    'เอกสารปฏิเสธกรรมธรรม์',
    'วันที่อัพเดตข้อมูล']
export const header4 = ['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', 'บ้านเลขที่', 'ซอย', 'ถนน', 'จังหวัด', 'อำเภอ', 'ตำบล', 'รหัสไปรษณีย์', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '']

export const header2 = ['บ้านเลขที่', 'ซอย', 'ถนน', 'จังหวัด', 'อำเภอ', 'ตำบล', 'รหัสไปรษณีย์']
export const listHeader = ['A1', 'B1', 'C1', 'D1', 'E1', 'F1', 'G1', 'H1', 'I1', 'J1', 'K1', 'L1', 'M1', 'N1', 'O1', 'P1', 'Q1', 'R1', 'S1', 'T1', 'U1', 'V1', 'W1', 'X1', 'Y1', 'Z1', 'AA1', 'AB1', 'AC1', 'AD1', 'AE1', 'AF1', 'AG1', 'AH1', 'AI1', 'AJ1', 'AK1', 'AL1','AM1', 'AN1', 'AO1', 'AP1', 'AQ1','AR1', 'AS1', 'AT1',
'A2', 'B2', 'C2', 'D2', 'E2', 'F2', 'G2', 'H2', 'I2', 'J2', 'K2', 'L2', 'M2', 'N2', 'O2', 'P2', 'Q2', 'R2', 'S2', 'T2', 'U2', 'V2', 'W2', 'X2', 'Y2', 'Z2', 'AA2', 'AB2', 'AC2', 'AD2', 'AE2', 'AF2', 'AG2', 'AH2', 'AI2', 'AJ2', 'AK2', 'AL2','AM2', 'AN2', 'AO2', 'AP2', 'AQ2','AR2', 'AS2']
export const merge = [
    { s: { r: 0, c: 0 }, e: { r: 1, c: 0 } },
    { s: { r: 0, c: 1 }, e: { r: 1, c: 1 } },
    { s: { r: 0, c: 2 }, e: { r: 1, c: 2 } },
    { s: { r: 0, c: 3 }, e: { r: 1, c: 3 } },
    { s: { r: 0, c: 4 }, e: { r: 1, c: 4 } },
    { s: { r: 0, c: 5 }, e: { r: 1, c: 5 } },
    { s: { r: 0, c: 6 }, e: { r: 1, c: 6 } },
    { s: { r: 0, c: 7 }, e: { r: 1, c: 7 } },
    { s: { r: 0, c: 8 }, e: { r: 1, c: 8 } },
    { s: { r: 0, c: 9 }, e: { r: 1, c: 9 } },
    { s: { r: 0, c: 10 }, e: { r: 1, c: 10 } },
    { s: { r: 0, c: 11 }, e: { r: 1, c: 11 } },
    { s: { r: 0, c: 12 }, e: { r: 1, c: 12 } },
    { s: { r: 0, c: 13 }, e: { r: 1, c: 13 } },
    { s: { r: 0, c: 14 }, e: { r: 1, c: 14 } },
    { s: { r: 0, c: 15 }, e: { r: 1, c: 15 } },
    { s: { r: 0, c: 16 }, e: { r: 1, c: 16 } },
    { s: { r: 0, c: 17 }, e: { r: 1, c: 17 } },
    { s: { r: 0, c: 18 }, e: { r: 1, c: 18 } },
    { s: { r: 0, c: 19 }, e: { r: 0, c: 25 } },
    { s: { r: 0, c: 26 }, e: { r: 1, c: 26 } },
    { s: { r: 0, c: 27 }, e: { r: 1, c: 27 } },
    { s: { r: 0, c: 28 }, e: { r: 1, c: 28 } },
    { s: { r: 0, c: 29 }, e: { r: 1, c: 29 } },
    { s: { r: 0, c: 30 }, e: { r: 1, c: 30 } },
    { s: { r: 0, c: 31 }, e: { r: 1, c: 31 } },
    { s: { r: 0, c: 32 }, e: { r: 1, c: 32 } },
    { s: { r: 0, c: 33 }, e: { r: 1, c: 33 } },
    { s: { r: 0, c: 34 }, e: { r: 1, c: 34 } },
    { s: { r: 0, c: 35 }, e: { r: 1, c: 35 } },
    { s: { r: 0, c: 36 }, e: { r: 1, c: 36 } },
    { s: { r: 0, c: 37 }, e: { r: 1, c: 37 } },
    { s: { r: 0, c: 38 }, e: { r: 1, c: 38 } },
    { s: { r: 0, c: 39 }, e: { r: 1, c: 39 } },
    { s: { r: 0, c: 40 }, e: { r: 1, c: 40 } },
    { s: { r: 0, c: 41 }, e: { r: 1, c: 41 } },
    { s: { r: 0, c: 42 }, e: { r: 1, c: 42 } },
    { s: { r: 0, c: 43 }, e: { r: 1, c: 43 } },
    { s: { r: 0, c: 44 }, e: { r: 1, c: 44 } },
    { s: { r: 0, c: 45 }, e: { r: 1, c: 45 } },
];
//19

export const style = {
    border: {
        top: {
            style: 'medium',
            color: '000000'
        },
        bottom: {
            style: 'medium',
            color: '000000'
        },
        left: {
            style: 'medium',
            color: '000000'
        },
        right: {
            style: 'medium',
            color: '000000'
        },
    },
    alignment: {
        horizontal: "center"
    },
    font: {
        name: 'angsana new',
        sz: 18,
        bold: true,
    },
    fill: {
        fgColor: {
            rgb: "FFD06F"
        }
    }
};


export const wscols = [
    { width: 20 },
    { width: 15 },
    { width: 30 },
    { width: 20 },
    { width: 20 },
    { width: 25 },
    { width: 15 },
    { width: 25 },
    { width: 25 },
    { width: 25 },
    { width: 25 },
    { width: 25 },
    { width: 25 },
    { width: 25 },
    { width: 25 },
    { width: 25 },
    { width: 25 },
    { width: 25 },
    { width: 25 },
    { width: 25 },
    { width: 25 },
    { width: 25 },
    { width: 25 },
    { width: 25 },
    { width: 25 },
    { width: 25 },
    { width: 25 },
    { width: 25 },
    { width: 25 },
    { width: 25 },
    { width: 25 },
    { width: 25 },
    { width: 25 },
    { width: 25 },
    { width: 25 },
    { width: 25 },
    { width: 25 },
    { width: 25 },
    { width: 25 },
    { width: 25 },
    { width: 25 },
    { width: 25 },
    { width: 25 },
    { width: 25 },
    { width: 25 },
    { width: 25 },
];