import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader
} from "../../_metronic/_partials/controls";
import { useSubheader } from "../../_metronic/layout";
import { baseURL, baseURLV1 } from "../service/API.js";
import moment from 'moment';
import TextField from "@material-ui/core/TextField";
import Chart from 'react-apexcharts'
import axios from "axios";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 3,
  },
  paper: {
    padding: theme.spacing(2),
    margin: 'auto',
    marginBottom: "20px",
    marginTop: "20px",
    maxWidth: 700,
  },
  image: {
    width: 128,
    height: 128,
  },
  img: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
  },
}));

export const DashboardPage = () => {
  const suhbeader = useSubheader();
  var d = new Date();
  d.setDate(d.getDate() - 30);
  const [dateFrom, setDateFrom] = useState(moment(d).format('YYYY-MM-DD'));
  const [dateTo, setDateTo] = useState(moment(new Date()).format('YYYY-MM-DD'));
  const classes = useStyles();
  suhbeader.setTitle("Dashboard Page");
  useEffect(() => {
    var dataPie = {
      report_id: 3,
      date_from: moment(dateFrom).format('YYYY-MM-DD'),
      date_to: moment(dateTo).format('YYYY-MM-DD'),
    }
    getPieChart(dataPie)

  }, []);
  const [barState, setBarState] = useState();
  const [pieState, setPieState] = useState();
  const getPieChart = (data) => {
    axios.get(`${baseURLV1}/dashboard/render?date_from=${data.date_from}&date_to=${data.date_to}`)
      .then((res) => {
        setPieState();
        if (res.data.length > 0) {
          var key = []
          var value = []
          res.data.map((data) => {
            value.push(data.total)
            key.push(data.text)
          })
          var option = {
            series: value,
            options: {
              chart: {
                toolbar: {
                  show: true,
                  offsetX: 0,
                  offsetY: 0,
                  tools: {
                    download: true,
                  },
                  export: {
                    svg: {
                      filename: "undefined",
                      columnDelimiter: ',',
                      headerCategory: 'category',
                      headerValue: 'value',
                      dateFormatter(timestamp) {
                        return new Date(timestamp).toDateString()
                      }
                    }
                  },
                  autoSelected: 'zoom'
                },
                width: 380,
                type: 'pie',
              },
              labels: key,
              responsive: [{
                breakpoint: 480,
                options: {
                  chart: {
                    width: 200
                  },
                  legend: {
                    position: 'bottom'
                  }
                }
              }]
            },
          }
          setPieState(option);
        }
      })
      .catch((err) => {
        console.log(err);
      })
  }
  const getBarChart = (data) => {
    axios.get(`${baseURLV1}/dashboard/summaryTransactionPerDay?date_from=${data.date_from}&date_to=${data.date_to}`)
      .then((res) => {
        setBarState()
        if (res.data.length > 0) {
          var item = []
          res.data.map((data) => {
            item.push({ x: data.text, y: data.total })
          })
          var option = {
            series: [{
              data: item,
            }],
            options: {
              chart: {
                type: 'bar',
                toolbar: { tools: { download: true } },
                height: 350
              },
              plotOptions: {
                bar: {
                  horizontal: true,
                }
              },
              xaxis: {
                type: 'category'
              },
            },

          }
          setBarState(option)
        }

      })
      .catch((err) => {
        console.log(err);
      })
  }
  const handleChangeDateFrom = (date) => {
    setDateFrom(date)
    var dataPie = {
      report_id: 3,
      date_from: moment(date).format('YYYY-MM-DD'),
      date_to: moment(dateTo).format('YYYY-MM-DD'),
    }
    getPieChart(dataPie)
    var dataBar = {
      report_id: 2,
      date_from: moment(date).format('YYYY-MM-DD'),
      date_to: moment(dateTo).format('YYYY-MM-DD'),
    }
    getBarChart(dataBar)
  }
  const handleChangeDateTo = (date) => {
    setDateTo(date)
    var dataPie = {
      report_id: 3,
      date_from: moment(dateFrom).format('YYYY-MM-DD'),
      date_to: moment(date).format('YYYY-MM-DD'),
    }
    getPieChart(dataPie)
    var dataBar = {
      report_id: 2,
      date_from: moment(dateFrom).format('YYYY-MM-DD'),
      date_to: moment(date).format('YYYY-MM-DD'),
    }
    getBarChart(dataBar)
  }
  return (
    <Card>
      <CardHeader title="Dashboard">
      </CardHeader>
      <CardBody>
        <TextField
          id="DateFrom"
          type="date"
          label="Date From"
          defaultValue={dateFrom}
          className=" mb-3 mr-3"
          style={{
            height: "38px",
            width: "185px",
            backgroundColor: "#F4F6F9",
            borderRadius: "5px",
            borderWidth: "0",
          }}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(name) => {
            handleChangeDateFrom(name.target.value)
          }}
        />
        <TextField
          id="DateFrom"
          type="date"
          label="Date To"
          defaultValue={dateTo}
          className=" mb-3 mr-3"
          style={{
            height: "38px",
            width: "185px",
            backgroundColor: "#F4F6F9",
            borderRadius: "5px",
            borderWidth: "0",
          }}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(name) => {
            handleChangeDateTo(name.target.value)
          }}
        />
        {pieState !== undefined ?
          <div className={classes.root}>
            <Paper className={classes.paper}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm container>
                  <Grid item xs container direction="column" spacing={2}>
                    <Grid item >
                      <>
                        <p className="text-center mt-4 mb-4">Summary Shipment by Country Code</p>
                        <Chart options={pieState.options} series={pieState.series} type="pie" width={580} />
                      </>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </div>
          : ''
        }

        {barState !== undefined ?
          <div className={classes.root}>
            <Paper className={classes.paper}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm container>
                  <Grid item xs container direction="column" spacing={2}>
                    <Grid item >
                      <>
                        <p className="text-center mt-4 mb-4">Summary Transaction per Day</p>
                        <Chart options={barState.options} series={barState.series} type="bar" width={580} />
                      </>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </div>
          : ''
        }
      </CardBody>
    </Card >
  );
};
