import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { DashboardPage } from "./pages/DashboardPage";
import { Report } from "./pages/Report";
import { ClaimManagement } from "./pages/ClaimManagement";
import { ClaimTransaction } from "./pages/ClaimTransaction";
import { UserManagement } from "./pages/UserManagement";
import { CreateUserRole } from "./pages/CreateUserRole";
import { EditUserRole } from "./pages/EditUserRole";
export default function BasePage() {
  // useEffect(() => {
  //   console.log('Base page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>

        <Redirect exact from="/" to="/dashboard" />
        <ContentRoute path="/Dashboard" component={DashboardPage} />
        <ContentRoute path="/Dashboard" component={DashboardPage} />
        <ContentRoute path="/Report" component={Report} />
        <ContentRoute path="/ClaimManagement" component={ClaimManagement} />
        <ContentRoute path="/ClaimTransaction" component={ClaimTransaction} />
        <ContentRoute path="/UserManagement" component={UserManagement} />
        <ContentRoute path="/CreateUserRole" component={CreateUserRole} />
        <ContentRoute path="/EditUserRole" component={EditUserRole} />
        

        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}
