export const status = [
    {
        "status": "01",
        "description": "แจ้งเคลม"
    },
    {
        "status": "02",
        "description": "ติดตามเอกสาร"
    },
    {
        "status": "03",
        "description": "ได้รับเอกสารเรียบร้อย"
    },
    {
        "status": "04",
        "description": "แจ้งผลอนุมัติ"
    },
    {
        "status": "05",
        "description": "แจ้งวันชำระสินไหม"
    },
    {
        "status": "06.1",
        "description": "แจ้งผลไม่พบกรมธรรม์"
    },
    {
        "status": "06.2",
        "description": "แจ้งผลไม่อนุมัติ"
    }
]

export const docStatus = [
    {
        "check": "04",
        "status": "PENDING",
        "description": "รอลูกค้าลงนาม"
    },
    {
        "check": "04",
        "status": "SUCCESS",
        "description": "ลูกค้าเปิดอ่านเอกสารแล้ว"
    },
    {   
        "check": "02",
        "status": "SUCCESS",
        "description": "ได้รับเอกสารแล้ว"
    },
    {   
        "check": "02",
        "status": "PENDING",
        "description": "รอรับเอกสาร"
    },
    {
        "check": "04",
        "status": "",
        "description": "ลูกค้าลงนามแล้ว"
    },
]