import React, { useState, useEffect, useRef, useMemo } from "react";
import {
  Card,
  CardBody,
  CardHeader
} from "../../_metronic/_partials/controls";
import {
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
} from "../../_metronic/_helpers";

import BootstrapTable from "react-bootstrap-table-next";
import { Modal, Table } from 'react-bootstrap';
import { useSubheader } from "../../_metronic/layout";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { baseURL } from "../service/API.js";
import { status } from "../service/status.js";
import "react-pagination-library/build/css/index.css";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import Pagination2 from "@material-ui/lab/Pagination";
import NumberFormat from 'react-number-format';
import Swal from "sweetalert2";
import { FcCheckmark } from 'react-icons/fc';
import { GrCopy } from 'react-icons/gr'
import moment from 'moment'
import TextField from "@material-ui/core/TextField";
import ReactPaginate from "react-paginate";
import * as XLSX from 'sheetjs-style'
import { header, header2, header4, wscols, listHeader, merge, style } from "../service/excelSetting.js";

import DatePicker from "react-datepicker";

export const ClaimManagement = () => {
  const [totalPage, setTotalPage] = useState(1);
  const intl = useIntl();
  const { user } = useSelector((state) => state.auth);
  const suhbeader = useSubheader();
  const allData = [];
  const [menu, setMenu] = useState([]);
  const [id, setId] = useState("");

  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [openImage, setOpenImage] = useState(false);
  const [imgSelect, setImgSelect] = useState("");
  const [limit, setLimit] = useState(10);
  const [policyList, setPolicyList] = useState('');
  const [policyPopupData, setPolicyPopupData] = useState([]);
  const [showPopup, setShowPopup] = useState(false)
  const [role, setRole] = useState('1')
  const [relateList, setRelateList] = useState([]);
  const [typeList, setTypeList] = useState([]);

  suhbeader.setTitle("บริการลูกค้า / แจ้งเคลมประกันภัยอื่นๆ / แก้ไข");
  const history = useHistory();
  const [dataAddress, setDataAddress] = useState([])

  const [pageNumber, setPageNumber] = useState(0)
  const [addressTran, setAddressTran] = useState([])

  const addressPerPage = 10
  const pageVisited = pageNumber * addressPerPage

  const displayAddress = addressTran.slice(pageVisited, pageVisited + addressPerPage).map((addressArr, index) => {
    return (<tr className="row-table"><td><p style={{ marginLeft: "15px", marginBottom: "0px" }}>ที่อยู่ {pageNumber >= 1 ? (pageVisited + 1) + index : index + 1} : {addressArr.address}</p></td></tr>)
  })

  useMemo(() => { setAddressTran(dataAddress) }, [dataAddress])
  const pageCount = Math.ceil(addressTran.length / addressPerPage)
  const [dataAddress1, setDataAddress1] = useState([])
  const [pageNumber1, setPageNumber1] = useState(0)
  const [addressTran1, setAddressTran1] = useState([])
  const addressPerPage1 = 10
  const pageVisited1 = pageNumber1 * addressPerPage1
  const displayAddress1 = addressTran1.slice(pageVisited1, pageVisited1 + addressPerPage1).map((result) => {
    return (<tr className="row-table"><td>{result.policy_peril_type}</td><td>{result.description}</td><td><NumberFormat value={result.org_sum_insured} displayType="text" thousandSeparator={true} decimalScale={2} /></td><td><NumberFormat value={result.org_gross_premium} displayType="text" thousandSeparator={true} decimalScale={2} /></td></tr>)
  })

  useMemo(() => { setAddressTran1(dataAddress1) }, [dataAddress1])

  const pageCount1 = Math.ceil(addressTran1.length / addressPerPage1)
  const [btnStatus, setBtnStatus] = useState('')
  const [start, setStart] = useState('')
  // const [start1, setStart1] = useState('')
  const [stop, setStop] = useState('')
  // const [stop1, setStop1] = useState('')

  const tryText = (data) => {
    try {
      return data.insurance_type.name
    } catch {
      return ' '
    }
  }

  useEffect(() => {
    localStorage.removeItem('edit')
    getList(page, limit, search, btnStatus);
    getRelate()
    getType()
    configRole()
  }, []);

  const configRole = () => {
    let role = localStorage.getItem('role')
    setRole(role)
  }
  const handlePageChange = (event, value) => {
    setPage(value);
    getList(value, limit, search, btnStatus);
  };

  const handlePageSizeChange = (event) => {
    setLimit(event.target.value);
    getList(1, event.target.value, search, btnStatus);
  };

  const statusFormat = (data) => {
    try {
      let temp = data
      const fixed04 = '04'
      if (data.includes(fixed04)) {
        return '04-แจ้งผลอนุมัติ'
      }
      status.map(item => {
        if (item.status == data) {
          temp = `${item.status}-${item.description}`
        }
      })
      return temp
    } catch {
      return data
    }
  }

  const relateFormat = (data) => {
    try {
      let temp = data
      relateList.map(item => {
        if (item.key == data) {
          temp = item.name
        }
      })
      return temp
    } catch {
      return data
    }
  }

  const docRequestFormat = (data, status) => {
    try {

      if (data == 'PENDING') {
        if (status == '02') {
          return 'รอลูกค้าแนบเอกสารเพิ่มเติม'

        } else if (status == '04') {
          return 'รอลูกค้าลงนาม'

        } else if (status == '06.2') {
          return 'ได้เปิดอ่านเอกสารแล้ว'
        }

      } else if (data == 'SUCCESS') {
        if (status == '02') {
          return 'ได้รับเอกสารแล้ว'

        } else if (status == '04') {
          return 'ได้รับเอกสารแล้ว'

        } else if (status == '06.2') {
          return 'ได้เปิดอ่านเอกสารแล้ว'
        }
      } else if (data == 'READING') {
        return 'ลูกค้าเปิดอ่านเอกสาร'
      }


      return ''
    } catch {
      return ''
    }
  }

  const subDocFormat = (data) => {
    try {
      let temp0 = []
      let temp = ''
      try {
        temp0 = JSON.parse(data)
      } catch {
        temp0 = []
      }
      temp0.map((item, index) => {
        if (index == 0) {
          temp = temp + `${item.subDoctype}`
        } else {
          temp = temp + `,${item.subDoctype}`
        }
      })
      return temp
    } catch {
      return ''
    }
  }

  const titleFormat = (data, type) => {
    try {
      let temp0 = []
      let temp = ''
      try {
        temp0 = JSON.parse(data)
      } catch {
        console.log('bad')
        temp0 = []
      }
      if (type == 1) {
        temp0.map((item, index) => {
          if (index == 0) {
            temp = temp + `${item.file_title}`
          } else {
            temp = temp + `,${item.file_title}`
          }
        })
      } else {
        temp = temp0.file_title
      }

      return temp
    } catch {
      return ''
    }
  }

  const getList = async (page, limit, search = '', status = '') => {
    let check = search != '' && search != undefined ? `&&search=${search}` : ''
    let tabStatus = status != '' && status != undefined ? `&&status=${status}` : ''
    let apiUrls = `${baseURL}/api/v1/claim?page=${page}&&limit=${limit}${check}${tabStatus}`;
    axios({
      method: "get",
      url: apiUrls,
    })
      .then(async (res) => {
        var totalpage = Math.ceil(res.data.resp.total / res.data.resp.limit);
        setTotalPage(totalpage);
        await res.data.resp.data.map(item => {

          let temp = item
          if (temp.policyType == null) {
            temp.policyType = { name: '' }
          }
          if (temp.claimer_firstName == null) {
            temp.claimer_firstName = `${temp.informer_firstName} ${temp.informer_lastName}`
          }
          if (!temp.policy_no) {
            item['policyNOshow'] = 'ไม่พบกรมธรรม์'
            item['isPopup'] = false
            item['color'] = 'red'
          } else {
            let temp1 = temp.policy_no.split(',')
            if (temp1.length > 1) {
              item['policyNOshow'] = 'พบมากกว่า 1 กรมธรรม์'
              item['isPopup'] = false
              item['color'] = 'yellow'
            } else if (temp1.length == 1) {
              item['policyNOshow'] = temp1[0]
              item['isPopup'] = true
              item['color'] = ''
            } else {
              item['policyNOshow'] = 'ไม่พบกรมธรรม์'
              item['isPopup'] = false
              item['color'] = 'red'
            }
          }

          allData.push(item)
          setPolicyList(res.data.resp.data.policy_no)
        })
        setMenu(allData)

      })
      .catch((err) => {
        console.log(err);
      });
  };


  const getRelate = async () => {
    let apiUrls = `${baseURL}/api/v1/relate`;
    axios({
      method: "get",
      url: apiUrls,

    })
      .then(async (res) => {
        setRelateList(res.data)
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getType = async () => {
    let apiUrls = `${baseURL}/api/v1/type`;
    axios({
      method: "get",
      url: apiUrls,

    })
      .then(async (res) => {
        setTypeList(res.data)
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const mapType = (data) => {
    try {
      let temp = data
      typeList.map(item => {
        if (item.key == data) {
          temp = item.name
        }
      })
      return temp
    } catch {
      return data
    }
  }


  const getListDate = async (start, stop) => {
    if (start == '' || stop == '') {
      return Swal.fire({
        title: "",
        text: "กรุณากรอกวันที่เริ่มต้น และวันที่สิ้นสุด !",
        icon: "error"
      });
    }
    let apiUrls = `${baseURL}/api/v1/claim?page=1&&limit=10000000&&start=${start}&&stop=${stop}`;
    axios({
      method: "get",
      url: apiUrls,
    })
      .then(async (res) => {
        let rows = []
        let temp2 = []
        let headers = []
        if (res.data.resp.data.length == 0) {
          return Swal.fire({
            title: "",
            text: "ไม่มีรายการในวันที่เลือก !",
            icon: "error"
          });
        }
        await res.data.resp.data.map(item => {
          let temp = item
          if (item['created_at']) {
            item['created_at'] = moment(item['created_at']).format(
              'DD/MM/YYYY'
            )
          }
          if (temp.policyType == null) {
            temp.policyType = { name: '' }
          }
          if (temp.claimer_firstName == null) {
            temp.claimer_firstName = `${temp.informer_firstName} ${temp.informer_lastName}`
          }

          if (!temp.policy_no) {
            item['policyNOshow'] = 'ไม่พบกรมธรรม์'
            item['isPopup'] = false
            item['color'] = 'red'
          } else {
            let temp1 = temp.policy_no.split(',')
            if (temp1.length > 1) {
              item['policyNOshow'] = 'พบมากกว่า 1 กรมธรรม์'
              item['isPopup'] = false
              item['color'] = 'yellow'
            } else if (temp1.length == 1) {
              item['policyNOshow'] = temp1[0]
              item['isPopup'] = true
              item['color'] = ''
            } else {
              item['policyNOshow'] = 'ไม่พบกรมธรรม์'
              item['isPopup'] = false
              item['color'] = 'red'
            }
          }
          rows.push({
            claim_ref_no: item.claim_ref_no,
            old_ref_noL: item.old_ref_no,
            claim_status: statusFormat(item.claim_status), //map กะ status
            files_status: docRequestFormat(item.doc_request_file_status, item.claim_status),// check กะ status
            claim_no: item.claim_no,
            policy_no: item.policyNOshow,
            created_at: item.created_at ? item.created_at : '-',
            informer_Type: item.informer_Type == "person" ? 'บุคคลธรรมดา' : 'นิติบุคคล',
            legal_name: item.legal_name,
            taxID: item.taxID,
            claimer_firstName: item.claimer_firstName,
            claimer_lastName: item.claimer_lastName,
            informer_firstName: item.informer_firstName,
            informer_lastName: item.informer_lastName,
            citizenID: item.citizenID,
            related: relateFormat(item.related), //map กับ relate
            mobile_number: item.mobile_number,
            telephone: item.telephone,
            email: item.email,
            address: item.address,
            address_detail: item.address_detail,
            address_detail2: item.address_detail2,
            province: item.ProvinceData ? item.ProvinceData.name_th : '',//map กับ province
            district: item.DistrictData ? item.DistrictData.name_th : '',//map กับ district
            sub_district: item.SubDistrictData ? item.SubDistrictData.name_th : '',//map กับ sub_district
            zipcode: item.zipcode,
            type: mapType(item.type),
            policy_type: item.policyType ? item.policyType.name : '',
            policy_type_detail: item.policy_type_detail,
            clause: item.cause ? item.cause.name : '',//map กับ clause
            contract: item.contract,
            description: item.description,
            incident_date: item.incident_date ? moment(item['incident_date']).format('DD/MM/YYYY') : '-',
            amount: item.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
            payment_amount: item.payment_amount,
            payment_date: item.payment_date ? moment(item['payment_date']).format('DD/MM/YYYY') : '-',
            payment: item.payment == 'promptpay' ? 'พร้อมเพย์' : 'โอนเข้าบัญชีธนาคาร',
            bank_account_trnasfer: item.bank_account_trnasfer,
            bank_account_branch: item.bank_account_branch,
            bank_account_number: item.bank_account_number,
            bank_account_name: item.bank_account_name,
            remark: item.remark,
            file: subDocFormat(item.file),
            approve_file_path: titleFormat(item.approve_file_path, 1),
            reject_file_path: titleFormat(item.reject_file_path, 2),
            updated_at: item.updated_at ? moment(item['updated_at']).format('DD/MM/YYYY') : '-',

          })

        })
        headers = Object.keys(rows[0])
        rows.map(item => {
          let data = []
          headers.map(item2 => {

            data.push(item[item2])
          })

          temp2.push(data)
        })
        //setMenuCSV(allData)


        const datas = [header, header4, ...temp2]

        const fileName = `export-kpi-claim.xlsx`
        const ws = (XLSX.WorkSheet = XLSX.utils.aoa_to_sheet([[]]))
        const wb = (XLSX.WorkBook = XLSX.utils.book_new())
        XLSX.utils.sheet_add_aoa(ws, datas)
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')
        ws["!merges"] = merge;

        listHeader.map(item => {
          ws[item].s = style
        })
        ws["!cols"] = wscols;
        XLSX.writeFile(wb, fileName)
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      // console.log(e, row, rowIndex)
    }
  };

  const getSearchPolicy = async (e, data, index) => {
    e.preventDefault()
    let hyperlink = document.getElementById(`hPolicy_${index}`)
    let divPolicyLoading = document.getElementById(`hPolicyLoading_${index}`)
    hyperlink.style.display = "none";
    divPolicyLoading.style.display = ""
    if (policyPopupData) {
      let findData = policyPopupData.policy_no == data.policyNOshow ? true : false
      if (findData == true) {
        setShowPopup(true)
      }
    }
    let apiUrls = `${baseURL}/api/v1/claim/search/policy?policy_no=${encodeURI(data.policyNOshow)}`;
    axios({
      method: "get",
      url: apiUrls,
    })
      .then(async (res) => {
        if (res.data.data) {
          setPolicyPopupData(res.data.data)
          setDataAddress(res.data.data.claimer_address.slice(0, 10000))
          setDataAddress1(res.data.data.policy_peril.slice(0, 10000))
          setShowPopup(true)
          hyperlink.style.display = "";
          divPolicyLoading.style.display = "none"
        }
      })
      .catch((err) => {
        hyperlink.style.display = "";
        divPolicyLoading.style.display = "none"
        console.log(err);
      });
  };



  const getMenuPerPage = async (p) => {
    await setPerPage(p);
    await getList(page, p, search, btnStatus);
  };

  const columns = [
    {
      text: "",
      dataField: "id",
      formatter: (id, row, rowIndex) => {
        return (
          <div style={{ width: '120px' }}>
            <a href={`/ClaimTransaction?ref_no=${row.claim_ref_no}`} target="_blank">
              <button
                type="button"
                className="btn btn-success mr-2 btn-sm"
              >
                {row.is_reference ? 'ดูเอกสาร' : 'แก้ไขเอกสาร'}
              </button>
            </a>

          </div >
        );
      },
    },
    {
      dataField: "claim_status",
      text: "สถานะ",
      sort: true,
      sortCaret: sortCaret,
      formatter: (id, row, rowIndex) => {
        console.log(row)
        return (
          <div style={{ width: '70px', alignContent: 'center', display: "flex" }}>
            {row.doc_request_file_status == 'PENDING' ?
              <p>
                {row.claim_status}{row.schedule_send_count ? "-" + row.schedule_send_count : ""}
                {row.is_reference ? <span style={{ fontSize: '25px' }}> <GrCopy /></span> : ""}
              </p>
              :
              (row.doc_request_file_status == 'SUCCESS' && (row.claim_status == '02' || row.claim_status == '04')) ?
                <div className="row">
                  <p className="col-0 mt-3 ml-3 mr-3">{row.claim_status}{row.schedule_send_count ? "-" + row.schedule_send_count : ""}</p>
                  {row.is_reference ? <span style={{ fontSize: '25px' }}> <GrCopy /></span> : <h1 className="col-0"> <FcCheckmark /></h1>}
                </div>
                :
                <p>
                  {row.claim_status}{row.schedule_send_count ? "-" + row.schedule_send_count : ""}
                  {row.is_reference ? <span style={{ fontSize: '25px' }}> <GrCopy /></span> : ""}
                </p>
            }

          </div>
        );
      },
    },
    {
      dataField: "claim_ref_no",
      text: "Referrence No",
      sort: true,
      sortCaret: sortCaret,
      formatter: (id, row, rowIndex) => {
        return (
          <div style={{ width: '110px', alignContent: 'center' }}>
            <a href={`/ClaimTransaction?ref_no=${row.claim_ref_no}`} target="_blank">{row.claim_ref_no}</a>
          </div>
        );
      },
    },
    {
      dataField: "claim_no",
      text: "Claim IIA",
      sort: true,
      sortCaret: sortCaret,
      formatter: (id, row, rowIndex) => {
        return (
          <div style={{ width: '120px', alignContent: 'center' }}>
            <p>{row.claim_no}</p>

          </div>
        );
      },
    },
    {
      dataField: "insurance_type.name",
      text: "ประเภทประกัน",
      sort: true,
      sortCaret: sortCaret,
      formatter: (id, row, rowIndex) => {
        return (
          <div style={{ width: '100px', alignContent: 'center' }}>
            <p>{tryText(row)}</p>

          </div>
        );
      },
    },
    {
      dataField: "policyType.name",
      text: "ประเภทกรมธรรม์",
      sort: true,
      sortCaret: sortCaret,
      formatter: (id, row, rowIndex) => {
        return (
          <div style={{ width: '80px', alignContent: 'center' }}>
            <p>{row.policyType.name}</p>

          </div>
        );
      },
    },
    {
      dataField: "policy_no",
      text: "เลขกรมธรรม์",
      sort: true,
      sortCaret: sortCaret,
      formatter: (id, row, rowIndex) => {
        // console.log(id, row, rowIndex)
        return (
          <div style={{ width: '120px', alignContent: 'center', backgroundColor: `${row.color}` }} className={row.color && row.color != "yellow" ? "def-text" : "def-text-black"}>
            {row.isPopup &&
              <>
                <a href="#" id={`hPolicy_${rowIndex}`} onClick={(e) => { getSearchPolicy(e, row, rowIndex) }}>{row.policyNOshow}</a>
                <div style={{ display: 'none' }} id={`hPolicyLoading_${rowIndex}`}>
                  <i className="fa fa-spinner fa-spin"></i> รอสักครู่...
                </div>
              </>
            }
            {!row.isPopup && <p>{row.policyNOshow}</p>}
          </div>
        );
      },
    },
    {
      dataField: "informer_Type",
      text: "ประเภทผู้เอาประกัน",
      sort: true,
      sortCaret: sortCaret,
      formatter: (id, row, rowIndex) => {
        return (
          <div style={{ width: '120px', alignContent: 'center' }}>
            <p>{row.informer_Type == 'corperate' ? 'นิติบุคคล' : 'บุคคลธรรมดา'}</p>

          </div>
        );
      },
    },
    {
      dataField: "claimer_type",
      text: "ผู้เเจ้งเคลมเป็นผู้เอาประกัน",
      sort: true,
      sortCaret: sortCaret,
      formatter: (id, row, rowIndex) => {
        return (
          <div style={{ width: '110px', alignContent: 'center' }}>
            <p>{row.claimer_type == '1' ? 'ใช่' : 'ไม่ใช่'}</p>

          </div>
        );
      },
    },
    {
      dataField: "claimer_firstName",
      text: "ชื่อผู้เอาประกัน",
      sort: true,
      sortCaret: sortCaret,
      formatter: (id, row, rowIndex) => {
        return (
          <div style={{ width: '100px', alignContent: 'center' }}>
            <p>{row.claimer_firstName}</p>

          </div>
        );
      },
    },
    {
      dataField: "claimer_lastName",
      text: "นามสกุลผู้เอาประกัน",
      sort: true,
      sortCaret: sortCaret,
      formatter: (id, row, rowIndex) => {
        return (
          <div style={{ width: '100px', alignContent: 'center' }}>
            <p>{row.claimer_lastName}</p>

          </div>
        );
      },
    },
    {
      dataField: "informer_firstName",
      text: "ชื่อผู้แจ้งเคลม",
      sort: true,
      sortCaret: sortCaret,
      formatter: (id, row, rowIndex) => {
        return (
          <div style={{ width: '100px', alignContent: 'center' }}>
            <p>{row.informer_firstName}</p>

          </div>
        );
      },
    },
    {
      dataField: "informer_lastName",
      text: "นามสกุลผู้แจ้งเคลม",
      sort: true,
      sortCaret: sortCaret,
      formatter: (id, row, rowIndex) => {
        return (
          <div style={{ width: '85px', alignContent: 'center' }}>
            <p>{row.informer_lastName}</p>

          </div>
        );
      },
    },
    {
      dataField: "mobile_number",
      text: "เบอร์โทรศัพท์",
      sort: true,
      sortCaret: sortCaret,
      formatter: (id, row, rowIndex) => {
        return (
          <div style={{ width: '100px', alignContent: 'center' }}>
            <p>{row.mobile_number}</p>

          </div>
        );
      },
    },
    {
      dataField: "payment_amount",
      text: "ยอดจ่ายสินไหม",
      sort: true,
      sortCaret: sortCaret,
      formatter: (id, row, rowIndex) => {
        return (
          <div style={{ width: '100px', alignContent: 'center' }}>
            <p>{(row.payment_amount)}</p>

          </div>
        );
      },
    },
    {
      dataField: "payment_date",
      text: "วันที่จ่ายสินไหม",
      sort: true,
      sortCaret: sortCaret,
      formatter: (id, row, rowIndex) => {
        return (
          <div style={{ width: '100px', alignContent: 'center' }}>
            <p>{row.payment_date ? moment(row.payment_date).format('DD/MM/YYYY') : ''}</p>

          </div>
        );
      },
    },
    {
      dataField: "created_at",
      text: "วันที่สร้างเอกสาร",
      sort: true,
      sortCaret: sortCaret,
      formatter: (id, row, rowIndex) => {
        return (
          <div style={{ width: '100px', alignContent: 'center' }}>
            <p>{moment(row.created_at).format('DD/MM/YYYY')}</p>

          </div>
        );
      },
    },
    {
      dataField: "updated_at",
      text: "วันที่อัพเดตข้อมูล",
      sort: true,
      sortCaret: sortCaret,
      formatter: (id, row, rowIndex) => {
        return (
          <div style={{ width: '117px', alignContent: 'center' }}>
            <p>{moment(row.updated_at).format('DD/MM/YYYY')}</p>

          </div>
        );
      },
    }
  ];

  const handleClose = () => {
    setId("");
    setOpenImage(false);
    setImgSelect("");
  };

  const [search, setSearch] = useState();
  const handleSearch = (e) => {
    getList(page, perPage, search, btnStatus);
  };

  const onChanges = (e) => {
    setPerPage(e.target.value);
    getMenuPerPage(e.target.value);
  };

  const rowStyleFormat = (row, rowIdx) => {
    let bg = 'white'
    if (row.claim_status == '01') {
      bg = 'Aquamarine'
    } else if (row.claim_status == '02') {
      bg = 'MediumTurquoise'
    } else if (row.claim_status == '03') {
      bg = 'powderblue'
    } else if (row.claim_status == '04') {
      bg = 'SteelBlue'
    } else if (row.claim_status == '05') {
      bg = 'WhiteSmoke'
    }
    if (row.is_reference) bg = 'lightgrey'
    return { backgroundColor: bg };
  }

  const handleManualPayment = () => {
    Swal.fire({
      title: '',
      text: "ยืนยันการเรียกฟังก์ชั่น",
      showCancelButton: true,
      confirmButtonText: 'ยืนยัน',
      cancelButtonText: 'ยกเลิก',
      showLoaderOnConfirm: true,
      preConfirm: (login) => {
        return fetch(`${baseURL}/api/v1/batch/payment/manual`)
          .then(response => {
            if (!response.ok) {
              throw new Error(response.statusText)
            }
            return response.json()
          })
          .catch(error => {
            Swal.showValidationMessage(
              `Request failed: ${error}`
            )
          })
      },
      allowOutsideClick: () => !Swal.isLoading()
    })
  }
  const handleManualNotification = () => {
    Swal.fire({
      title: '',
      text: "ยืนยันการเรียกฟังก์ชั่น",
      showCancelButton: true,
      confirmButtonText: 'ยืนยัน',
      cancelButtonText: 'ยกเลิก',
      showLoaderOnConfirm: true,
      preConfirm: (login) => {
        return fetch(`${baseURL}/api/v1/batch/notification/manual`)
          .then(response => {
            if (!response.ok) {
              throw new Error(response.statusText)
            }
            return response.json()
          })
          .catch(error => {
            Swal.showValidationMessage(
              `Request failed: ${error}`
            )
          })
      },
      allowOutsideClick: () => !Swal.isLoading()
    })
  }

  const handler = (event) => {
    if (event.key == 'Enter') {
      getList(page, perPage, search, btnStatus);
    }
  };

  const handleChangeAddressInModal = ({ selected }) => {
    setPageNumber(selected)
  }

  const handleChangeAddressInModal1 = ({ selected }) => {
    setPageNumber1(selected)
  }

  // console.log(start)
  // console.log(start1)
  // console.log(stop)
  // console.log(stop1)

  return (
    <Card>
      <CardHeader title="รายการเคลม">
      </CardHeader>
      <CardBody>
        <div className="row" style={{ marginButtom: "25px" }}>
          <div className="col-sm-4">
            <input
              type="text"
              className="form-control"
              placeholder={intl.formatMessage({ id: "104002", defaultMessage: "ค้นหา" })}
              style={{ width: "100%" }}
              onChange={(e) => setSearch(e.target.value)}
              onKeyPress={(e) => handler(e)}
            />
          </div>
          <div className="col-sm-2">
            <button
              type="button"
              id="button"
              className="btn btn-primary"
              style={{ width: "100%" }}
              onClick={() => handleSearch()}
            >
              {intl.formatMessage({ id: "104003", defaultMessage: "ค้นหา" })}
            </button>
          </div>
          {role == 1 ?
            <div className="col align-self-end">
              <div className="row">
                <div className="col-sm-6">
                  <button
                    type="button"
                    id="button"
                    className="btn btn-outline-info"
                    style={{ width: "100%" }}
                    onClick={() => handleManualPayment()}
                  >
                    MANUAL PAYMENT
                  </button>
                </div>
                <div className="col-sm-6">
                  <button
                    type="button"
                    id="button"
                    className="btn btn-outline-info"
                    style={{ width: "100%" }}
                    onClick={() => handleManualNotification()}
                  >
                    MANUAL NOTIFICATION
                  </button>
                </div>
              </div>
            </div>
            : null}
        </div>


        <div className="group-btn-search mb-5">

          <div className="row" style={{ width: '100%' }}>

            <div className="col-6 mt-3" >
              <button
                type="button"
                className="btn btn-outline-dark"
                style={btnStatus == '' ? { backgroundColor: "black", color: "white" } : { backgroundColor: "white" }}
                onClick={() => {
                  setBtnStatus('')
                  getList(1, limit)
                }}>All</button>
              <button type="button" className="btn btn-outline-dark" style={btnStatus == '01' ? { backgroundColor: "black", color: "white" } : { backgroundColor: "white" }} onClick={() => {
                setBtnStatus('01')
                getList(1, limit, '', '01')
              }}>01</button>
              <button type="button" className="btn btn-outline-dark" style={btnStatus == '02' ? { backgroundColor: "black", color: "white" } : { backgroundColor: "white" }} onClick={() => {
                setBtnStatus('02')
                getList(1, limit, '', '02')
              }}>02</button>
              <button type="button" className="btn btn-outline-dark" style={btnStatus == '03' ? { backgroundColor: "black", color: "white" } : { backgroundColor: "white" }} onClick={() => {
                setBtnStatus('03')
                getList(1, limit, '', '03')
              }}>03</button>
              <button type="button" className="btn btn-outline-dark" style={btnStatus == '04' ? { backgroundColor: "black", color: "white" } : { backgroundColor: "white" }} onClick={() => {
                setBtnStatus('04')
                getList(1, limit, '', '04')
              }}>04</button>
              <button type="button" className="btn btn-outline-dark" style={btnStatus == '05' ? { backgroundColor: "black", color: "white" } : { backgroundColor: "white" }} onClick={() => {
                setBtnStatus('05')
                getList(1, limit, '', '05')
              }}>05</button>
              <button type="button" className="btn btn-outline-dark" style={btnStatus == '06.1' ? { backgroundColor: "black", color: "white" } : { backgroundColor: "white" }} onClick={() => {
                setBtnStatus('06.1')
                getList(1, limit, '', '06.1')
              }}>06.1</button>
              <button type="button" className="btn btn-outline-dark" style={btnStatus == '06.2' ? { backgroundColor: "black", color: "white" } : { backgroundColor: "white" }} onClick={() => {
                setBtnStatus('06.2')
                getList(1, limit, '', '06.2')
              }}>06.2</button>
            </div>


            <div className="col-6" style={{ width: "100%" }}>

              <div className="row" >

                <div className="col" >

                  <DatePicker
                    placeholderText="วว/ดด/ปปปป"
                    dateFormat="dd/MM/yyyy"
                    selected={start}
                    className="field-date1"
                    onChange={date => { setStart(date) }}
                  />

                  {/* <TextField
                    //disabled={!(!claimNoRef && !isSubmit) || is_reference}
                    id="DateFrom"
                    type="date"
                    label={intl.formatMessage({ id: "###", defaultMessage: "วันที่เริ่มต้น" })}
                    className=" mb-3 mr-3"
                    value={start}
                    style={{
                      height: '15px',
                      width: '100%',
                      backgroundColor: '#F4F6F9',
                      borderRadius: '5px',
                      borderWidth: '0',
                    }}
                    InputLabelProps={{
                      shrink: true
                    }}
                    onChange={name => {
                      setStart(name.target.value)
                    }}
                  /> */}



                </div>

                <div className="col" >

                  <DatePicker
                    placeholderText="วว/ดด/ปปปป"
                    dateFormat="dd/MM/yyyy"
                    selected={stop}
                    className="field-date1"
                    onChange={date => { setStop(date) }}
                  />


                  {/* <TextField
                    //disabled={!(!claimNoRef && !isSubmit) || is_reference}
                    id="DateFrom"
                    type="date"
                    label={intl.formatMessage({ id: "###", defaultMessage: "วันที่สิ้นสุด" })}
                    className=" mb-3 mr-3"
                    value={stop}
                    style={{
                      height: '15px',
                      width: '100%',
                      backgroundColor: '#F4F6F9',
                      borderRadius: '5px',
                      borderWidth: '0'
                    }}
                    InputLabelProps={{
                      shrink: true
                    }}
                    onChange={name => {
                      setStop(name.target.value)
                    }}
                  /> */}


                </div>

                <div className="col">
                  <button
                    type="button"
                    id="button"
                    className="btn btn-primary"
                    //style={{ width: "100%" }}
                    onClick={() => {
                      getListDate(start, stop)
                    }}
                  >
                    {intl.formatMessage({ id: "104003", defaultMessage: "export excel" })}
                  </button>
                </div>

              </div>
            </div>
          </div>

        </div>

        <BootstrapTable
          rowStyle={rowStyleFormat}
          keyField="id"
          data={menu}
          rowEvents={rowEvents}
          columns={columns}
          wrapperClasses="table-responsive table-cm"
          bordered={false}
          classes="header-table table table-head-custom table-vertical-center overflow-hidden table-fixed"
          bootstrap4
        >
        </BootstrapTable>
        <div className=" col-lg-12">
          <div className="row">
            <div className="col-lg-6">
              <div className="mt-3">
                {"Items per Page: "}
                <select onChange={handlePageSizeChange} value={limit}>
                  <option value={10}>10</option>
                  <option value={25}>25</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                </select>
              </div>
            </div>
            <div className="col-lg-6">
              <Pagination2
                className="mt-3 "
                count={totalPage}
                page={page}
                siblingCount={1}
                boundaryCount={1}
                variant="outlined"
                shape="rounded"
                onChange={handlePageChange}
              />
            </div>
          </div>
        </div>
        <Modal
          className="modal-center"
          show={showPopup}
          onHide={handleClose}
          size='lg'
        >
          <Modal.Header >
            <h3>รายละเอียดกรมธรรม์</h3>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" onClick={() => setShowPopup(false)}>
              <span aria-hidden="true" className="x">&times;</span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <p>1. เลขกรมธรรม์: {policyPopupData.policy_no}</p>
            <p>2. ชื่อผู้เอาประกัน: {policyPopupData.claimer_fullname}</p>
            <p>3. สถานที่ตั้งทรัพย์สินที่เอาประกัน: </p>

            {/* {policyPopupData.claimer_address &&
              policyPopupData.claimer_address.map((result, index) => {
                return (<div>
                  <p style={{ marginLeft: "15px" }}>ที่อยู่ {index + 1} : {result.address}</p>
                </div>
                );
              })} */}


            {displayAddress.length > 0 ? <Table bordered>
              <thead className="table-header-address">
                <tr>
                  <th>รายการที่อยู่</th>
                </tr>
              </thead>
              <tbody>
                {displayAddress}
              </tbody>
            </Table> : ""}


            {(displayAddress != '') ? <ReactPaginate
              className="my-3 paginationBttns"
              previousLabel={"ย้อนกลับ"}
              nextLabel={"ถัดไป"}
              pageCount={pageCount}
              onPageChange={handleChangeAddressInModal}
              containerClassName={"paginationBttns"}
              previousLinkClassName={"previousBttn"}
              nextLinkClassName={"nextBttn"}
              disabledClassName={"paginationDisabled"}
              activeClassName={"paginationActive"}
              breakLabel="..."
            /> : ""}

            <p>4. ทุนประกันภัย: <NumberFormat value={policyPopupData.pol_sum_insure} displayType="text" thousandSeparator={true} decimalScale={2} /></p>
            <p>5. วันที่เริ่มคุ้มครอง: {moment(policyPopupData.effective_date).format('DD/MM/YYYY')}</p>
            <p style={{ marginLeft: "15px" }}>วันที่สิ้นสุดความคุ้มครอง: {moment(policyPopupData.expiry_date).format('DD/MM/YYYY')}</p>
            <p>6. Sub Class: {policyPopupData.sub_class}</p>
            <p>7. แหล่งงาน: {policyPopupData.department}</p>
            <p>8. เบี้ยประกันภัย: <NumberFormat value={policyPopupData.pol_net_gross_prem} displayType="text" thousandSeparator={true} decimalScale={2} /></p>
            <p>9. ภัยเพิ่มตามกรมธรรม์:</p>
            {policyPopupData.policy_peril && policyPopupData.policy_peril.length > 0 &&
              <Table responsive className="table table-bordered">
                <thead>
                  <tr>
                    <th scope="col">Policy Peril Type</th>
                    <th scope="col">Description</th>
                    <th scope="col">Org Sum Insured</th>
                    <th scope="col">Org Gross Premium</th>
                  </tr>
                </thead>
                <tbody>
                  {displayAddress1}
                </tbody>
              </Table>
            }

            {(displayAddress1 != '') ? <ReactPaginate
              className="my-3 paginationBttns"
              previousLabel={"ย้อนกลับ"}
              nextLabel={"ถัดไป"}
              pageCount={pageCount1}
              onPageChange={handleChangeAddressInModal1}
              containerClassName={"paginationBttns"}
              previousLinkClassName={"previousBttn"}
              nextLinkClassName={"nextBttn"}
              disabledClassName={"paginationDisabled"}
              activeClassName={"paginationActive"}
              breakLabel="..."
            /> : ""}

          </Modal.Body>
          <Modal.Footer>
            <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={() => setShowPopup(false)}>ปิดหน้าต่าง</button>
          </Modal.Footer>
        </Modal>

      </CardBody>
    </Card >
  );
};
