import React, { useState, useEffect } from "react";
import {
    Card,
    CardBody,
    CardHeader,
    CardHeaderToolbar,
} from "../../_metronic/_partials/controls";
import BootstrapTable from "react-bootstrap-table-next";
import {
    NoRecordsFoundMessage,
    PleaseWaitMessage,
    sortCaret,
    headerSortingClasses,
} from "../../_metronic/_helpers";
import paginationFactory from "react-bootstrap-table2-paginator";
import { useSubheader } from "../../_metronic/layout";
import axios from "axios";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import { baseURLV1, imageURL } from "../service/API.js";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Modal from "@material-ui/core/Modal";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import Pagination from "react-pagination-library";
import "react-pagination-library/build/css/index.css";
import { useSelector } from "react-redux";
import moment from "moment-timezone"
import { useIntl } from "react-intl";
import Pagination2 from "@material-ui/lab/Pagination";


export const UserManagement = () => {
    const intl = useIntl();
    const { user } = useSelector((state) => state.auth);
    const suhbeader = useSubheader();
    const allData = [];
    const [menu, setMenu] = useState([]);
    const [id, setId] = useState("");
    const [total, setTotal] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [lastPage, setLastPage] = useState(0);
    const [page, setPage] = useState(1);
    const [openImage, setOpenImage] = useState(false);
    const [imgSelect, setImgSelect] = useState("");
    const [currentPage, setCurrentPage] = useState();
    const [selestSearch, setSelestSearch] = useState(1);
    const [limit, setLimit] = useState(10);
    const [totalPage, setTotalPage] = useState(1);
    const [totalSize, setTotalSize] = useState(100);


    suhbeader.setTitle(intl.formatMessage({ id: "099254", defaultMessage: "Menu" }));
    const history = useHistory();

    useEffect(() => {
        
        getMenu(page, perPage);
    }, []);
    const handlePageChange = (event, value) => {
        setPage(value);
        getMenu(value, limit);
    };
    const getMenu = async (page, perPage, search="") => {
        let apiUrls = `${baseURLV1}/user/list/?limit=${perPage}&page=${page}${search !== undefined && search !== "" ? "&search=" + search : ""}`;
        let token = localStorage.getItem("token");
        const resultAll = axios({
            method: "get",
            url: apiUrls,
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${token}`,
            },
        })
            .then(async (res) => {
                console.log(res.data.data)
                await res.data.data.map(async (data, index) => {
                    data = {
                        id: data.id,
                        firstname: data.firstname,
                        lastname: data.lastname,
                        email: data.email,
                        is_active: data.is_active,
                        role: data.role,
                        created_at: data.created_at,
                        updated_at: data.updated_at,
                        
                    };
                    // console.log(data)
                    allData.push(data);
                    // console.log(allData)
                    var totalpage = Math.ceil(res.data.total / res.data.limit);
                    setTotalPage(totalpage);
                    setTotalSize(res.data.total);
                    setLimit(res.data.limit);

                });
                
                /*var number = Number(res.data.total) / Number(res.data.limit);
                setLastPage(Math.ceil(number));
        
                await setTotal(res.data.total);*/
                await setMenu(allData);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const getMenuPage = async (p) => {
        await getMenu(p, perPage);
    };

    const getMenuPerPage = async (p) => {
        await setPerPage(p);
        await getMenu(page, p);
    };
    const handlePageSizeChange = (event) => {
        setLimit(event.target.value);
        getMenu(1, event.target.value);
    };
    const columns = [
        
        {
            dataField: "firstname",
            text: 'firstname',
            //sort: true,
            
            formatter: (id, row, rowIndex) => {
                return (
                    <div style={{ width: '80px', textAlign:"center" }}>
                        <p>
                            {row.firstname}
                        </p>
                    </div>
                );
            },
        },
        {
            dataField: "lastname",
            text: 'lastname',
            //sort: true,
            
            formatter: (id, row, rowIndex) => {
                return (
                    <div style={{ width: '80px', textAlign:"center" }}>
                        <p>
                            {row.lastname}
                        </p>
                    </div>
                );
            },
        },
        {
            dataField: "email",
            text: intl.formatMessage({ id: "099256", defaultMessage: "email" }),
            //sort: true,
           
            formatter: (id, row, rowIndex) => {
                return (
                    <div style={{ width: '80px', textAlign:"center" }}>
                        <p>
                            {row.email}
                        </p>
                    </div>
                );
            },
        },
        {
            dataField: "role",
            text: intl.formatMessage({ id: "099258", defaultMessage: "permission" }),
           // sort: true,
           
            formatter: (id, row, rowIndex) => {
                return (
                    <div style={{ width: '120px', textAlign:"center" }}>
                        <p>
                            {row.role == 1 ? 'admin' : row.role == 2 ? 'editor': 'viewr'}
                        </p>
                    </div>
                );
            },
        },
        
        {
            dataField: "created_at",
            text: intl.formatMessage({ id: "099164", defaultMessage: "Create At" }),
            //sort: true,
            
            formatter: (id, row, rowIndex) => {
                return (
                    <div style={{ width: '100px' }}>
                        <p>
                            {row.created_at}
                        </p>
                    </div>
                );
            },
        },
        {
            dataField: "updated_at",
            text: intl.formatMessage({ id: "099165", defaultMessage: "Update At" }),
            //sort: true,
           
            formatter: (id, row, rowIndex) => {
                return (
                    <div style={{ width: '100px' }}>
                        <p>
                            {row.updated_at}
                        </p>
                    </div>
                );
            },
        },
       
        {
            text: "",
            dataField: "id",
            formatter: (id, row, rowIndex) => {
                return (
                    <div style={{width:'150px'}}>
                        <button
                            type="button"
                            className="btn btn-primary mr-2"
                            onClick={() => editItem({ id, row, rowIndex })}
                        >
                            {intl.formatMessage({ id: "099023", defaultMessage: "แก้ไข" })}
                        </button>

                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() => deleteItem({ row, rowIndex })}
                        >
                            {intl.formatMessage({ id: "099022", defaultMessage: "ลบ" })}
                        </button>
                    </div>
                );
            },
        },
    ];

    const handleClose = () => {
        setId("");
        setOpenImage(false);
        setImgSelect("");
    };

    const editItem = (data) => {
        history.push("/EditUserRole", data);
    };

    const deleteItem = (data) => {
        let token = localStorage.getItem("token");
        Swal.fire({
            title: "ต้องการทำการลบผู้ใช้?",
            text: "ผู้ใช้จะถูกลบออกจากระบบ !",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "ลบ!",
            cancelButtonText: "ยกเลิก",
        }).then((result) => {
            if (result.value) {
                axios
                    .delete(`${baseURLV1}/user/remove/${data.row.id}`, {
                        headers: {
                            Accept: "application/json",
                            Authorization: `Bearer ${token}`,
                        },
                    })
                    .then((res) => {
                        Swal.fire(
                            "ทำการลบผู้ใช้!",
                            "ผู้ใช้งานนี้ถูกลบจากระบบ.",
                            "success"
                        );
                        getMenu(page, perPage);
                    })
                    .catch((err) => {
                        Swal.fire(
                            "ทำการลบผู้ใช้!",
                            "การลบไม่สำเร็จ",
                            "error"
                        );
                    });
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire("Cancelled", "Your Menu is safe :)", "error");
            }
        });
    };

    const [search, setSearch] = useState();
    const handleSearch = () => {
        getMenu(page, perPage, search);
    };

    const onChanges = (e) => {
        setPerPage(e.target.value);
        getMenuPerPage(e.target.value);
    };

    const pagination = paginationFactory({
        page: lastPage,
        alwaysShowAllBtns: true,
        withFirstAndLast: false,
        pageListRenderer: ({ pages, onPageChange }) => {
            return (
                <div className="dataTables_length" id="datatable-basic_length">
                    <label>
                        <Pagination
                            currentPage={currentPage}
                            totalPages={lastPage}
                            changeCurrentPage={async (numPage) => {
                                onPageChange(numPage);
                                await setPage(numPage);
                                await setCurrentPage(numPage);
                                await getMenuPage(numPage);
                            }}
                            theme="bottom-border form form-control-sm"
                        />
                    </label>
                </div>
            );
        },
        sizePerPageRenderer: ({
            options,
            currSizePerPage,
            onSizePerPageChange,
        }) => (
            <div className="dataTables_length" id="datatable-basic_length">
                <label>
                    {
                        <select
                            name="datatable-basic_length"
                            aria-controls="datatable-basic"
                            className="form-control form-control-sm"
                            onChange={(e) => {
                                onChanges(e);
                                onSizePerPageChange(e.target.value);
                            }}
                        >
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </select>
                    }
                </label>
            </div>
        ),
    });

    const handleChangeSelestSearch = (event) => {
        setSelestSearch(Number(event.target.value));
    };

    return (
        <Card>
            <CardHeader title={intl.formatMessage({ id: "099254", defaultMessage: "หัวข้อ" })}>
                <CardHeaderToolbar>
                    <Link to="/CreateUserRole">
                        <button type="button" className="btn btn-primary">
                            {intl.formatMessage({ id: "099027", defaultMessage: "สร้าง" })}
                        </button>
                    </Link>
                </CardHeaderToolbar>
            </CardHeader>
            <CardBody>
                {/*<div class="form-row align-items-center">
                    <div class="col-sm-4">
                        <input
                            type="text"
                            className="form-control"
                            placeholder={intl.formatMessage({ id: "099026", defaultMessage: "พิมพ์ชื่อที่ต้องการ" })}
                            style={{ width: "100%" }}
                            onChange={(e) => setSearch(e.target.value)}
                        />
                    </div>
                    <div class="col-sm-2">
                        <button
                            type="button"
                            id="button"
                            className="btn btn-primary"
                            style={{ width: "100%" }}
                            onClick={() => handleSearch()}
                        >
                            {intl.formatMessage({ id: "099025", defaultMessage: "ค้นหา" })}
                        </button>
                    </div>
                </div>*/}

                <BootstrapTable
                    
                    keyField="id"
                    data={menu}
                    
                    columns={columns}
                    wrapperClasses="table-responsive table-cm"
                    bordered={false}
                    classes="header-table table table-head-custom table-vertical-center overflow-hidden table-fixed"
                    bootstrap4
                >
                    <PleaseWaitMessage entities={menu} />
                    <NoRecordsFoundMessage entities={menu} />
                </BootstrapTable>
                <div className=" col-lg-12">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="mt-3">
                                {"Items per Page: "}
                                <select onChange={handlePageSizeChange} value={limit}>
                                    <option value={10}>10</option>
                                    <option value={25}>25</option>
                                    <option value={50}>50</option>
                                    <option value={100}>100</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <Pagination2
                                className="mt-3 "
                                count={totalPage}
                                page={page}
                                siblingCount={1}
                                boundaryCount={1}
                                variant="outlined"
                                shape="rounded"
                                onChange={handlePageChange}
                            />
                        </div>
                    </div>
                </div>
            </CardBody>

            <Modal
                open={openImage}
                disablePortal
                disableEnforceFocus
                disableAutoFocus
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <TransformWrapper
                            defaultScale={1}
                            defaultPositionX={200}
                            defaultPositionY={100}
                        >
                            {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                <React.Fragment>
                                    <TransformComponent>
                                        <div className="modal-body">
                                            <img
                                                style={{
                                                    width: "100%",
                                                    height: "100%",
                                                    maxWidth: "500px",
                                                    maxHeight: "500px",
                                                }}
                                                src={imgSelect}
                                            />
                                        </div>
                                    </TransformComponent>
                                    <div className="modal-footer">
                                        <div className="tools">
                                            <button
                                                type="button"
                                                className="btn btn-primary mr-1"
                                                onClick={zoomIn}
                                            >
                                                +
                                            </button>
                                            <button
                                                type="button"
                                                className="btn btn-primary mr-1"
                                                onClick={zoomOut}
                                            >
                                                -
                                            </button>
                                        </div>
                                        <button
                                            type="button"
                                            className="btn btn-secondary"
                                            onClick={() => handleClose()}
                                        >
                                            Close
                                        </button>
                                    </div>
                                </React.Fragment>
                            )}
                        </TransformWrapper>
                    </div>
                </div>
            </Modal>
        </Card>
    );
};
