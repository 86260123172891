import React, { useState, useEffect } from "react";
import { useSubheader } from "../../_metronic/layout";
import {
    Card,
    CardBody,
    CardHeader,
} from "../../_metronic/_partials/controls"//"../../../_metronic/_partials/controls";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { baseURL, filePathURL, webURL, baseURLV1 } from "../service/API.js";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { useIntl } from "react-intl";
import { Formik, Form, Field, ErrorMessage } from "formik";

export const EditUserRole = (props) => {
    const intl = useIntl();
    const suhbeader = useSubheader();
    suhbeader.setTitle(intl.formatMessage({ id: "099259", defaultMessage: "จัดการผู้ใช้" }));
    const history = useHistory();
    const [firstname, setFirstname] = useState();
    const [lastname, setLastname] = useState();
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [passwordConfirm, setPasswordConfirm] = useState();
    const [openCheck, setOpenCheck] = useState(false);
    const [is_duplicate, setIs_duplicate] = useState(false);
    const [is_email, setIs_Email] = useState(true);


    const [role, setRole] = useState()


    const [stateItem_image_path, setStateItem_image_path] = useState({
        previewItem_image_path: null,
        rawItem_image_path: null,
        imageURLItem_image_path: null,
    });
    function ValidateEmail(email) {
        if (String(email)
            .toLowerCase()
            .match(
                /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
            )) {
            console.log('in')
            setIs_Email(true)
        } else {
            console.log('in2')

            setIs_Email(false)
        }
        //alert("You have entered an invalid email address!")
        //return (false)
    }
    const checkEmail = (email) => {
        var events = {
            email: email,
        };

        let apiUrls = `${baseURLV1}/user/checkEmail`;
        let token = localStorage.getItem("token");
        const resultAll = axios({
            method: "post",
            url: apiUrls,
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${token}`,
            },
            data: events,
        })
            .then(async (res) => {
                console.log(res)
                if (res.data.is_duplicate && email != props.location.state.row.email) {
                    setIs_duplicate(true)
                } else {
                    setIs_duplicate(false)

                }
            })
            .catch((err) => {
                Swal.fire(
                    "Create !",
                    "Your can not create. !! " + err,
                    "error"
                );
            });
    }

    const ChangePassword = () => {
        if (password != passwordConfirm) {
            return Swal.fire(
                "",
                "กรุณายืนยันรหัสผ่านให้ถูกต้อง. !! ",
                "error"
            );
        }
        var events = {
            id: props.location.state.row.id,
            passwordNew: password,
        };

        let apiUrls = `${baseURLV1}/user/editPassword`;
        let token = localStorage.getItem("token");
        const resultAll = axios({
            method: "post",
            url: apiUrls,
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${token}`,
            },
            data: events,
        })
            .then(async (res) => {
                Swal.fire(
                    "",
                    "Change password success !! ",
                    "success"
                );
            })
            .catch((err) => {
                Swal.fire(
                    "Create !",
                    "Your can not change. !! " + err,
                    "error"
                );
            });
    }

    useEffect(() => {
        setFirstname(props.location.state.row.firstname)
        setLastname(props.location.state.row.lastname)
        setEmail(props.location.state.row.email)
        //setPassword(props.location.state.row.password)
        setRole(String(props.location.state.row.role))

    }, []);

    const AlertError = (message) => {
        Swal.fire("Menu Management!", message, "error");
    };

    const onSubmit = async () => {
        if (firstname === undefined || firstname === "") {
            return AlertError("กรุณากรอกชื่อ");
        }
        if (lastname === undefined || lastname === "") {
            return AlertError("กรุณากรอกนามสกุล");
        }

        if (email === undefined || email === "") {
            return AlertError("กรุณากรอกอีเมล");
        }
        if (is_duplicate && email != props.location.state.email) {
            return AlertError("อีเมลซ้ำ");
        }
        if (role === undefined || role === "") {
            return AlertError("กรุณากรอกสิทธิ์");
        }


        var events = {

            firstname: firstname,
            lastname: lastname,
            email: email,
            role: role,
            //password: password

            //order_item: displayOrder,
        };

        let apiUrls = `${baseURLV1}/user/edit/${props.location.state.id}`;
        let token = localStorage.getItem("token");
        const resultAll = axios({
            method: "put",
            url: apiUrls,
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${token}`,
            },
            data: events,
        })
            .then(async (res) => {
                history.goBack();
            })
            .catch((err) => {
                Swal.fire(
                    "Create !",
                    "Your can not create. !! " + err,
                    "error"
                );
            });
    };


    return (
        <>
            <Card>
                <CardHeader title={intl.formatMessage({ id: "099259", defaultMessage: "จัดการผู้ใช้" })}></CardHeader>
                <CardBody>


                    <div className="form-inline" style={{ marginBottom: "25px" }}>

                        <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                            <span style={{ color: "red" }}>
                                *
                            </span>
                            {intl.formatMessage({ id: "099255", defaultMessage: "ชื่อ" })}
                        </p>
                        <input
                            value={firstname}

                            name="item_name_en"
                            maxLength="100"
                            style={{
                                height: "38px",
                                width: "530px",
                                backgroundColor: "#F4F6F9",
                                borderRadius: "5px",
                                borderWidth: "0",
                            }}
                            onChange={(item_name_en) =>
                                setFirstname(item_name_en.target.value)
                            }
                        />
                    </div>
                    <div className="form-inline" style={{ marginBottom: "25px" }}>

                        <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                            <span style={{ color: "red" }}>
                                *
                            </span>
                            {intl.formatMessage({ id: "099256", defaultMessage: "นามสกุล" })}
                        </p>
                        <input
                            value={lastname}
                            name="item_name_en"
                            maxLength="100"
                            style={{
                                height: "38px",
                                width: "530px",
                                backgroundColor: "#F4F6F9",
                                borderRadius: "5px",
                                borderWidth: "0",
                            }}
                            onChange={(item_name_en) =>
                                setLastname(item_name_en.target.value)
                            }
                        />
                    </div>

                    <div className="form-inline" style={{ marginBottom: "25px" }}>

                        <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                            <span style={{ color: "red" }}>
                                *
                            </span>
                            {intl.formatMessage({ id: "099257", defaultMessage: "email" })}
                        </p>
                        <input
                            value={email}
                            name="item_name_en"
                            maxLength="100"
                            style={{
                                height: "38px",
                                width: "530px",
                                backgroundColor: "#F4F6F9",
                                borderRadius: "5px",
                                borderWidth: "0",
                            }}
                            onChange={(item_name_en) => {
                                setEmail(item_name_en.target.value)
                                checkEmail(item_name_en.target.value)
                                ValidateEmail(item_name_en.target.value)
                            }

                            }
                        />
                        {(is_duplicate || !is_email) && <p style={{ marginTop: "75px", marginLeft: "20%", position: "absolute", color: "red" }}>{is_duplicate ? `พบ e-mail ซ้ำ` : `กรุณากรอก e-mail ให้ถูกต้อง`}</p>}

                    </div>



                    <div className="form-inline" style={{ marginBottom: "25px" }}>
                        <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                            <span style={{ color: "red" }}>
                                *
                            </span>
                            {intl.formatMessage({ id: "099258", defaultMessage: "สิทธิ์" })}{" "}
                        </p>
                        <select
                            value={role}
                            as="select"
                            style={{
                                height: "38px",
                                width: "530px",
                                backgroundColor: "#F4F6F9",
                                borderRadius: "5px",
                                borderWidth: "0",
                            }}
                            onChange={e => setRole(e.target.value)}
                        >
                            <option value="">{intl.formatMessage({ id: "###", defaultMessage: "Please select" })}</option>
                            <option key={1} value={'1'}>
                                admin
                            </option>
                            <option key={2} value={'2'}>
                                editor
                            </option>
                            <option key={2} value={'3'}>
                                viewer
                            </option>
                        </select>
                    </div>

                    <div className="form-inline mb-5">
                        <a
                            style={{
                                textDecoration: "#007BFF underline",
                                color: "#007BFF",
                            }}
                            data-toggle="modal"
                            data-target="#modal-EditPassword"
                            onClick={() => setOpenCheck(!openCheck)}
                        >
                            {openCheck ? intl.formatMessage({ id: "113093", defaultMessage: "cancel change password" })
                                : intl.formatMessage({ id: "113068", defaultMessage: "change password" })}
                        </a>
                    </div>

                    {openCheck ? (
                        <div>
                            <div className="form-inline">
                                <p
                                    style={{
                                        marginTop: "15px",
                                        float: "left",
                                        width: "20%",
                                    }}
                                >
                                    {intl.formatMessage({ id: "109011", defaultMessage: "password" })} :{" "}
                                </p>
                                <input
                                    type="password"
                                    name="password"
                                    className="form-control"
                                    maxLength={12}
                                    placeholder={intl.formatMessage({ id: "109011", defaultMessage: "password" })}
                                    style={{
                                        height: "38px",
                                        width: "530px",
                                        backgroundColor: "#F4F6F9",
                                        borderRadius: "5px",
                                        borderWidth: "0",
                                    }}
                                    onChange={
                                        e => {
                                            setPassword(e.target.value)
                                        }
                                    }
                                />
                            </div>
                            <div className="form-inline">
                                <p
                                    style={{
                                        marginTop: "15px",
                                        float: "left",
                                        width: "20%",
                                    }}
                                ></p>
                                {/*<ErrorMessage className="colorError" name="password">
                          {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                        </ErrorMessage>*/}
                            </div>
                            <div className="form-inline">
                                <p
                                    style={{
                                        marginTop: "15px",
                                        float: "left",
                                        width: "20%",
                                    }}
                                >
                                    {intl.formatMessage({ id: "113067", defaultMessage: "Confirm Password" })} :{" "}
                                </p>
                                <input
                                    type="password"
                                    name="confirmPassword"
                                    className="form-control"
                                    maxLength={12}
                                    placeholder={intl.formatMessage({ id: "113067", defaultMessage: "Confirm Password" })}
                                    style={{
                                        height: "38px",
                                        width: "530px",
                                        backgroundColor: "#F4F6F9",
                                        borderRadius: "5px",
                                        borderWidth: "0",
                                    }}
                                    onChange={
                                        e => {
                                            setPasswordConfirm(e.target.value)
                                        }
                                    }
                                />
                                <button type="button" onClick={() => {
                                    ChangePassword()
                                }} className="btn btn-secondary wbt ml-3">
                                    {intl.formatMessage({ id: "099065", defaultMessage: "เปลี่ยนรหัสผ่าน" })}
                                </button>
                            </div>

                            <div className="form-inline">
                                <p
                                    style={{
                                        marginTop: "15px",
                                        float: "left",
                                        width: "20%",
                                    }}
                                ></p>
                                {/*<ErrorMessage className="colorError" name="confirmPassword">
                          {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                        </ErrorMessage>*/}
                            </div>
                        </div>
                    ) : null}


                    <div
                        className="form-inline"
                        style={{
                            marginBottom: "25px",
                        }}
                    >
                        <div
                            style={{
                                marginTop: "15px",
                                float: "left",
                                width: "20%",
                            }}
                        ></div>
                        <Link to="/UserManagement">
                            <button type="button" className="btn btn-secondary wbt mr-5">
                                {intl.formatMessage({ id: "099065", defaultMessage: "ยกเลิก" })}
                            </button>
                        </Link>
                        <button className="btn btn-primary" onClick={() => onSubmit()}>
                            {intl.formatMessage({ id: "099064", defaultMessage: "บันทึก" })}
                        </button>
                    </div>
                </CardBody>
            </Card>
        </>
    );
};
