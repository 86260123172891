/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */
import React, { useState } from "react";
import SVG from "react-inlinesvg";
import { useHistory } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../_helpers";
import { useSelector } from "react-redux";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Modal from "@material-ui/core/Modal";
import Swal from "sweetalert2";
import { baseURL, baseURLV1 } from "../../../../../app/service/API.js";
import axios from "axios";

export function QuickUser() {
  const history = useHistory();
  const { user, accessToken } = useSelector(state => state.auth);
  const [openCheck, setOpenCheck] = useState(false);


  const handleClose = () => {
    setOpenCheck(false);
  };

  const handleSubmitPassword = async (values, { setSubmitting }) => {
    await setSubmitting(false);
    await editPassword(values);
  };


  const editPassword = async (values) => {

    let data = {
      passwordOld: values.password,
      passwordNew: values.newpassword,
    };

    if (data.passwordOld && data.passwordNew) {
      Swal.fire({
        title: "Are you sure?",
        text: "You will change password !",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then((result) => {

        if (result.value) {
          axios.post(`${baseURLV1}/user/editPassword`, data, {
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${accessToken}`,
            }
          })
            .then((res) => {
              Swal.fire("Change password!", "Your Change password.", "success");
              handleClose();
              history.push("/logout");
            })
            .catch((err) => {
              Swal.fire(
                'Created !',
                'Your can not. !! ' + err,
                'error'
              )
            });

        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "Your Cancel change password", "error");
        }
      });


    }
  };

  const logoutClick = () => {
    const toggle = document.getElementById("kt_quick_user_toggle");
    if (toggle) {
      toggle.click();
    }
    history.push("/logout");
  };

  return (
    <div id="kt_quick_user" className="offcanvas offcanvas-right offcanvas p-10">
      <div className="offcanvas-header d-flex align-items-center justify-content-between pb-5">
        <h3 className="font-weight-bold m-0">
          User Profile
            {/* <small className="text-muted font-size-sm ml-2">12 messages</small> */}
        </h3>
        <a
          href="#"
          className="btn btn-xs btn-icon btn-light btn-hover-primary"
          id="kt_quick_user_close"
        >
          <i className="ki ki-close icon-xs text-muted" />
        </a>
      </div>

      <div
        className="offcanvas-content pr-5 mr-n5"
      >
        <div className="d-flex align-items-center mt-5">
          <div
            className="symbol symbol-100 mr-5"
          >
            <div className="symbol-label">KPI</div>

          </div>
          <div className="d-flex flex-column">
            <a
              href="#"
              className="font-weight-bold font-size-h5 text-dark-75 text-hover-primary"
            >
              {user.data.fullname}
            </a>
            {/* <div className="text-muted mt-1">Application Developer</div> */}
            <div className="navi mt-2">
              <a href="#" className="navi-item">
                <span className="navi-link p-0 pb-2">
                  {/* <span className="navi-icon mr-1">
                    <span className="svg-icon-lg svg-icon-primary">
                      <SVG
                          src={toAbsoluteUrl(
                              "/media/svg/icons/Communication/Mail-notification.svg"
                          )}
                      ></SVG>
                    </span>
                  </span> */}
                  {/* <span className="navi-text text-muted text-hover-primary">
                    jm@softplus.com
                  </span> */}
                </span>
              </a>
            </div>
            {/* <Link to="/logout" className="btn btn-light-primary btn-bold">
                Sign Out
              </Link> */}
            <button className="btn btn-light-primary m-1" onClick={() => setOpenCheck(true)}>Change Password</button>
            <button className="btn btn-light-danger m-1" onClick={logoutClick}>Sign out</button>
          </div>
        </div>

        <div className="separator separator-dashed mt-8 mb-5" />

        <Modal
          open={openCheck}
          disablePortal
          disableEnforceFocus
          disableAutoFocus
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">

              <div className="offcanvas-header d-flex align-items-center justify-content-between pb-5" style={{ marginTop: "30px", marginLeft: "30px", marginRight: "30px" }}>
                <span style={{ fontSize: "20px" }}>Change Password</span>
                <a
                  href="#"
                  className="btn btn-xs btn-icon btn-light btn-hover-primary"
                  id="kt_quick_user_close"
                  onClick={() => handleClose()}
                >
                  <i className="ki ki-close icon-xs text-muted" />
                </a>
              </div>

              <div className="modal-body">
                <Formik
                  initialValues={{
                    password: "",
                    newpassword: "",
                  }}
                  validate={(values) => {
                    let errors = {};

                    if (!values.password)
                      errors.password = "Password is required.";

                    if (!values.newpassword)
                      errors.newpassword = "New password is required.";

                    if (!values.confirmpassword)
                      errors.confirmpassword = "Confirm password is required.";

                    if (values.newpassword && values.confirmpassword) {
                      if (values.newpassword !== values.confirmpassword) {
                        errors.confirmpassword = "Password does not match.";
                      }
                    }

                    //check if my values have errors
                    return errors;
                  }}
                  onSubmit={handleSubmitPassword}
                  render={(formProps) => {
                    return (
                      <Form className="col">
                        <div className="row">

                          <Field
                            type="password"
                            name="password"
                            className="form-control"
                            placeholder="Current Password"
                            style={{
                              height: "38px",
                              width: "530px",
                              backgroundColor: "#F4F6F9",
                              borderRadius: "5px",
                              borderWidth: "0",
                            }}
                          />
                        </div>
                        <div className="form-inline">
                          <ErrorMessage className="colorError" name="password">
                            {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                          </ErrorMessage>
                        </div>

                        <div className="row mt-1">
                          <Field
                            type="password"
                            name="newpassword"
                            className="form-control"
                            placeholder="New Password"
                            style={{
                              height: "38px",
                              width: "530px",
                              backgroundColor: "#F4F6F9",
                              borderRadius: "5px",
                              borderWidth: "0",
                            }}
                          />
                        </div>

                        <div className="form-inline">
                          <ErrorMessage
                            className="colorError"
                            name="newpassword"
                          >
                            {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                          </ErrorMessage>
                        </div>

                        <div className="row mt-1">
                          <Field
                            type="password"
                            name="confirmpassword"
                            className="form-control"
                            placeholder="Confirm Password"
                            style={{
                              height: "38px",
                              width: "530px",
                              backgroundColor: "#F4F6F9",
                              borderRadius: "5px",
                              borderWidth: "0",
                            }}
                          />
                        </div>

                        <div className="form-inline">
                          <ErrorMessage
                            className="colorError"
                            name="confirmpassword"
                          >
                            {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                          </ErrorMessage>
                        </div>

                        <div className="offcanvas-header d-flex align-items-center justify-content-center pb-5" style={{ marginTop: "30px" }}>
                          <button
                            className="btn btn-danger"
                            type="submit"
                            disabled={formProps.isSubmitting}
                          >
                            Confirm
                          </button>
                        </div>
                      </Form>
                    );
                  }}
                />
              </div>
            </div>
          </div>
        </Modal>

        {/* <div className="navi navi-spacer-x-0 p-0">
            <a href="/user/profile" className="navi-item">
              <div className="navi-link">
                <div className="symbol symbol-40 bg-light mr-3">
                  <div className="symbol-label">
                  <span className="svg-icon svg-icon-md svg-icon-success">
                    <SVG
                        src={toAbsoluteUrl(
                            "/media/svg/icons/General/Notification2.svg"
                        )}
                    ></SVG>
                  </span>
                  </div>
                </div>
                <div className="navi-text">
                  <div className="font-weight-bold">My Profile</div>
                  <div className="text-muted">
                    Account settings and more
                    {" "}
                    <span className="label label-light-danger label-inline font-weight-bold">
                    update
                  </span>
                  </div>
                </div>
              </div>
            </a>

            <a href="/user/profile" className="navi-item">
              <div className="navi-link">
                <div className="symbol symbol-40 bg-light mr-3">
                  <div className="symbol-label">
                  <span className="svg-icon svg-icon-md svg-icon-warning">
                    <SVG
                        src={toAbsoluteUrl(
                            "/media/svg/icons/Shopping/Chart-bar1.svg"
                        )}
                    ></SVG>
                  </span>
                  </div>
                </div>
                <div className="navi-text">
                  <div className="font-weight-bold">My Messages</div>
                  <div className="text-muted">Inbox and tasks</div>
                </div>
              </div>
            </a>

            <a href="/user/profile" className="navi-item">
              <div className="navi-link">
                <div className="symbol symbol-40 bg-light mr-3">
                  <div className="symbol-label">
                  <span className="svg-icon svg-icon-md svg-icon-danger">
                    <SVG
                        src={toAbsoluteUrl(
                            "/media/svg/icons/Files/Selected-file.svg"
                        )}
                    ></SVG>
                  </span>
                  </div>
                </div>
                <div className="navi-text">
                  <div className="font-weight-bold">My Activities</div>
                  <div className="text-muted">Logs and notifications</div>
                </div>
              </div>
            </a>

            <a href="/user/profile" className="navi-item">
              <div className="navi-link">
                <div className="symbol symbol-40 bg-light mr-3">
                  <div className="symbol-label">
                  <span className="svg-icon svg-icon-md svg-icon-primary">
                    <SVG
                        src={toAbsoluteUrl(
                            "/media/svg/icons/Communication/Mail-opened.svg"
                        )}
                    ></SVG>
                  </span>
                  </div>
                </div>
                <div className="navi-text">
                  <div className="font-weight-bold">My Tasks</div>
                  <div className="text-muted">latest tasks and projects</div>
                </div>
              </div>
            </a>
          </div> */}

        {/* <div className="separator separator-dashed my-7"></div>

          <div>
            <h5 className="mb-5">Recent Notifications</h5>

            <div className="d-flex align-items-center bg-light-warning rounded p-5 gutter-b">
            <span className="svg-icon svg-icon-warning mr-5">
              <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")}
                  className="svg-icon svg-icon-lg"
              ></SVG>
            </span>

              <div className="d-flex flex-column flex-grow-1 mr-2">
                <a
                    href="#"
                    className="font-weight-normal text-dark-75 text-hover-primary font-size-lg mb-1"
                >
                  Another purpose persuade
                </a>
                <span className="text-muted font-size-sm">Due in 2 Days</span>
              </div>

              <span className="font-weight-bolder text-warning py-1 font-size-lg">
              +28%
            </span>
            </div>

            <div className="d-flex align-items-center bg-light-success rounded p-5 gutter-b">
            <span className="svg-icon svg-icon-success mr-5">
              <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
                  className="svg-icon svg-icon-lg"
              ></SVG>
            </span>
              <div className="d-flex flex-column flex-grow-1 mr-2">
                <a
                    href="#"
                    className="font-weight-normal text-dark-75 text-hover-primary font-size-lg mb-1"
                >
                  Would be to people
                </a>
                <span className="text-muted font-size-sm">Due in 2 Days</span>
              </div>

              <span className="font-weight-bolder text-success py-1 font-size-lg">
              +50%
            </span>
            </div>

            <div className="d-flex align-items-center bg-light-danger rounded p-5 gutter-b">
            <span className="svg-icon svg-icon-danger mr-5">
              <SVG
                  src={toAbsoluteUrl(
                      "/media/svg/icons/Communication/Group-chat.svg"
                  )}
                  className="svg-icon svg-icon-lg"
              ></SVG>
            </span>
              <div className="d-flex flex-column flex-grow-1 mr-2">
                <a
                    href="#"
                    className="font-weight-normel text-dark-75 text-hover-primary font-size-lg mb-1"
                >
                  Purpose would be to persuade
                </a>
                <span className="text-muted font-size-sm">Due in 2 Days</span>
              </div>

              <span className="font-weight-bolder text-danger py-1 font-size-lg">
              -27%
            </span>
            </div>

            <div className="d-flex align-items-center bg-light-info rounded p-5">
            <span className="svg-icon svg-icon-info mr-5">
              <SVG
                  src={toAbsoluteUrl("/media/svg/icons/General/Attachment2.svg")}
                  className="svg-icon svg-icon-lg"
              ></SVG>
            </span>

              <div className="d-flex flex-column flex-grow-1 mr-2">
                <a
                    href="#"
                    className="font-weight-normel text-dark-75 text-hover-primary font-size-lg mb-1"
                >
                  The best product
                </a>
                <span className="text-muted font-size-sm">Due in 2 Days</span>
              </div>

              <span className="font-weight-bolder text-info py-1 font-size-lg">
              +8%
            </span>
            </div>
          </div>
         */}
      </div>
    </div>
  );
}
