export default function setupAxios(axios, store) {
  axios.interceptors.request.use(
    config => {
      const {
        auth: { authToken, accessTokenPrivilege }
      } = store.getState();

      var check_privilage = config.url.search("privilege-api");

      if (check_privilage > 0) {
        config.headers.Authorization = `Bearer ${accessTokenPrivilege}`;
      } else {
        config.headers.Authorization = `Bearer ${authToken}`;
      }
      return config;
    },
    err => {
      if (err.response.status === 401) {
        window.location.assign("/logout");
      }
      return Promise.reject(err)
    }
  );
}
