import axios from "axios";
import { baseURL, baseURLV1 } from "../../../service/API";

export const LOGIN_URL = `${baseURLV1}/auth/login`;
export const ME_URL = `${baseURLV1}/auth/me`;


export function login(email, password) {
  return axios.post(LOGIN_URL, { email, password });
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.

  let token = localStorage.getItem("token");
  return axios({
    method: "post",
    url: ME_URL,
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
}
